import React, { useEffect } from "react";
import { useOnScreen } from "../../hooks/useOnScreen";

import LineJoin from "./LineJoin";

const InsightHeader = ({
  title,
  subtitle,
  id,
  lineJoin = false,
  onShowSection,
  onHideSection,
}) => {
  const [ref, isOnScreen] = useOnScreen(0.9, false);

  useEffect(() => {
    if (isOnScreen) {
      onShowSection(id);
    } else {
      onHideSection(id);
    }
  }, [isOnScreen, id, onShowSection, onHideSection]);

  return (
    <div className="container" ref={ref}>
      <div className="row py-4">
        <div className="col-12 col-lg-9 ms-auto position-relative">
          {lineJoin && <LineJoin colors={lineJoin} />}

          <h2 id={id}>{title}</h2>
          <h3>{subtitle}</h3>
        </div>
      </div>
    </div>
  );
};

export default InsightHeader;
