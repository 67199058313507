import React from "react";
import PillBar from "./PillBar";
import styles from "./styles.module.scss";

import { getLowestIndustries, getTopIndustries } from "../../util/duration";

const InsightLoyalty = () => {
  const top = getTopIndustries();
  const bottom = getLowestIndustries();

  return (
    <div className={styles.container}>
      <p className={styles.title}>Industries with highest employee loyalty</p>

      {top.map(({ industry, avg_years_job }) => (
        <div key={industry} className={styles.pillContainer}>
          <span className={styles.label}>{industry}</span>
          <PillBar value={avg_years_job} color="retained" />
        </div>
      ))}

      <p className={styles.title}>Industries with lowest employee loyalty</p>

      {bottom.map(({ industry, avg_years_job }) => (
        <div key={industry} className={styles.pillContainer}>
          <span className={styles.label}>{industry}</span>
          <PillBar value={avg_years_job} color="pink" />
        </div>
      ))}
    </div>
  );
};

export default InsightLoyalty;
