import React from "react";
import { motion } from "framer-motion";
import { useOnScreen } from "../../../hooks/useOnScreen";
import styles from "./styles.module.scss";

const HEIGHT = 160;

const LineJoin = ({ colors }) => {
  const [ref, isOnScreen] = useOnScreen(0.1, false);

  return (
    <div
      ref={ref}
      className={styles.container}
      style={{ height: HEIGHT, top: 30 - HEIGHT }}
    >
      <svg className={styles.svg}>
        <motion.path
          d={`M 60 10 C -30 10 -30 ${HEIGHT - 10} 60 ${HEIGHT - 10}`}
          stroke={colors[1]}
          strokeWidth={16}
          opacity={0.25}
          fill="transparent"
          animate={{ pathLength: isOnScreen ? 1 : 0 }}
          transition={{ duration: 1 }}
        />
        <circle cx={60} cy={10} r={8} fill={colors[0]} />
        <circle cx={60} cy={HEIGHT - 10} r={8} fill={colors[1]} />
      </svg>
    </div>
  );
};

export default LineJoin;
