import React, {useState, useRef} from "react";
import {Link} from "gatsby";
import findIndex from "lodash/findIndex";

import Box from "../components/Box";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Contents from "../components/Contents";
import InsightPercentBox from "../components/InsightPercentBox";
import InsightHeader from "../components/InsightHeader";
import InsightContent from "../components/InsightContent";
import InsightBars from "../components/InsightBars";
import InsightLoyalty from "../components/InsightLoyalty";
import InsightLearnMore from "../components/InsightLearnMore";
import InsightsFooter from "../components/InsightsFooter";
import InsightDots from "../components/InsightDots";

import {getChangeFromYear, getTopIndustriesFor} from "../util/overview";

import movesData from "../data/moves.json";
import retainData from "../data/retain.json";
import attractData from "../data/attract.json";

import IndustryLearnMoreImage from "../images/learnMore/industry.png";
import TrackerLearnMoreImage from "../images/learnMore/tracker.png";

const topJoining = getTopIndustriesFor("to");
const topLeaving = getTopIndustriesFor("from");

const contents = [
    {
        label: "Changing Course",
        id: "course",
    },
    {
        label: "Talent movements",
        id: "talent",
    },
    {
        label: "Deal breakers and motivators",
        id: "drive",
    },
    {
        label: "Making it stick",
        id: "stick",
    },
    {
        label: "Looking ahead",
        id: "ahead",
    },
];

const InsightsPage = () => {
    const [section, setSection] = useState("course");
    const contentsRef = useRef(null);

    const onShowSection = (id) => {
        setSection(id);
    };

    const onHideSection = (id) => {
        if (id === section) {
            const index = findIndex(contents, {id: section});

            if (index > 0) {
                setSection(contents[index - 1].id);
            }
        }
    };

    const {changed_industry_perc} = getChangeFromYear("2015-2022");

    return (
        <Layout>
            <Header className="py-5">
                <h1 className="my-0">Market Insights</h1>
            </Header>

            <div ref={contentsRef} className="position-relative">
                <Contents
                    contents={contents}
                    current={section}
                    container={contentsRef}
                />

                <InsightHeader
                    id="course"
                    title="Changing Course"
                    subtitle="How career changes are reshaping recruitment and retention"
                    onShowSection={onShowSection}
                    onHideSection={onHideSection}
                />

                <InsightContent>
                    <p>The ‘career ladder’ is outdated.</p>
                    <p>
                        Today our working lives resemble more of a spiral staircase, with
                        job changes helping us secure the work we want or need.
                    </p>
                    <InsightDots right/>
                    <p>
                        For some, this means side stepping into a new role or business to
                        build out their CV or transferable skills. For others, climbing the
                        ranks is far less important than things like job security or
                        work-life balance.
                    </p>
                    <div className="row">
                        <div className="col-12 col-md-3 mb-4 mb-md-0">
                            <InsightPercentBox
                                value={changed_industry_perc}
                                text="of workers changed industries"
                            />
                        </div>
                        <div className="col-12 col-md-9">
                            <p>
                                In fact, our analysis of more than 6.3 million career movements over the past seven
                                years found that when changing jobs, people are just as likely to start work in a
                                different industry as they are to progress within their own (57% versus 43%).
                            </p>
                        </div>
                    </div>
                    <InsightDots left/>
                    <p>
                        While the rate of job changes has held relatively stable over time,
                        the motivations driving these career leaps – and the industries
                        benefiting from them – have shifted significantly.
                    </p>
                    <p>
                        Our survey found that three quarters of the UK workforce (75%) are
                        thinking about making a career change in the future, meaning that
                        employers have the opportunity to harness cross-industry trends to
                        shape their attraction and retention strategies.
                    </p>
                    <InsightDots right/>
                    <p>
                        Use our <Link to="/">Career Change Tracker</Link> to see which
                        industries provide – and pinch – your top performers, or read on
                        below for an in-depth view of talent movement trends in the UK.
                    </p>
                </InsightContent>

                <InsightHeader
                    id="talent"
                    title="Talent movements"
                    subtitle="Which industries make the most gains or losses?"
                    onShowSection={onShowSection}
                    onHideSection={onHideSection}
                    lineJoin={["#8C0E51", "#F06C30"]}
                />

                <InsightContent flipped>
                    <p>
                        When a person decides to make a career leap, where do they land?
                    </p>

                    <Box faded>
                        <div className="row">
                            <div className="col-12 col-md-6 mb-4 mb-md-0">
                                <InsightBars
                                    title="Industries gaining workers"
                                    subtitle={
                                        <>
                                            Where the highest proportion of
                                            <br/>
                                            job moves are people <strong>joining</strong>
                                        </>
                                    }
                                    data={topJoining.map(({to_perc, industry, to}, index) => ({
                                        label: industry,
                                        value: to_perc,
                                        percentLabel: to_perc,
                                    }))}
                                    color="to"
                                />
                            </div>
                            <div className="col-12 col-md-6 text-end">
                                <InsightBars
                                    title="Industries losing workers"
                                    subtitle={
                                        <>
                                            Where the highest proportion of
                                            <br/>
                                            job moves are people <strong>leaving</strong>
                                        </>
                                    }
                                    data={topLeaving.map(({from_perc, industry, from}) => ({
                                        label: industry,
                                        value: from_perc,
                                        percentLabel: from_perc,
                                    }))}
                                    color="from"
                                    flip
                                />
                            </div>
                            <div className="col-12">
                                <span className="color-grey type-16 pt-4 d-block">
                                  <strong>Note:</strong> Industry sample sizes vary based on
                                  reported data.
                                </span>
                            </div>
                        </div>
                    </Box>
                    <InsightDots left/>

                    <p className="pt-4">
                        Looking at the total number of job moves within each industry, our data shows that the following
                        industries saw the highest proportion of people joining: the Public Sector, Business Management,
                        Charity, Military and Farming and Agriculture.
                    </p>
                    <p>
                        However, if you were to consider the actual number of career changers entering different
                        industries, IT, Sales, Marketing, Consulting and Education come out on top.
                    </p>
                    <p>
                        Some of these transfers could be linked to a wider shift in{" "}
                        <a
                            href="https://www.totaljobs.com/recruiter-advice/89-of-people-expect-their-jobs-to-be-partly-remote-after-the-covid-19-pandemic-according-to-global-talent-survey-of-over-208000-workers/?WT.mc_id=E_A_BA_hub"
                            target="_blank"
                            rel="noreferrer"
                        >
                            how candidates are making decisions about their career
                        </a>
                        , with an increasing number of jobseekers vetting a company’s values
                        before applying for a job.
                    </p>
                    <InsightDots right/>
                    <p>
                        You’ll notice that some industries – like Public Sector and Farming and Agriculture – feature on
                        both lists, with a high percentage of movers both joining and leaving the industry. This kind of
                        churn could be indicative of seasonal hiring trends, particularly for an industry like Farming
                        and Agriculture.
                    </p>
                    <InsightDots left/>
                    <p>
                        Interestingly, our survey data found that only 29% of people who
                        switched industries are now performing the same or a similar role,
                        while two-thirds (64%) have an entirely different remit. Despite
                        this, only 41% needed to retrain to land the new job, highlighting
                        the value of{" "}
                        <a
                            href="https://www.totaljobs.com/recruiter-advice/how-in-demand-industries-can-source-candidates-using-transferable-skills"
                            target="_blank"
                            rel="noreferrer"
                        >
                            transferable skills
                        </a>{" "}
                        for cross-industry jumps.
                    </p>
                    <InsightDots right/>
                    <p>
                        But no matter which industry a person lands in or how they got there
                        – they rarely look back.
                    </p>
                    <p>
                        Only 18% of people who moved jobs during the Covid-19 pandemic are planning
                        to return to their original industry.
                    </p>
                    <p>
                        A third (30%) of job-hoppers even intend to change industries again
                        in the future, building up a variety of experience and skills along
                        the way.
                    </p>
                </InsightContent>

                <InsightHeader
                    id="drive"
                    title="Deal breakers and motivators"
                    subtitle="What drives career changes?"
                    onShowSection={onShowSection}
                    onHideSection={onHideSection}
                    lineJoin={["#8C0E51", "#0043A6"]}
                />

                <InsightContent>
                    <p>
                        What gives workers the push – or pull – they need to start in a new
                        industry?
                    </p>
                    <p>In a word: lifestyle.</p>
                    <InsightDots left/>

                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <Box faded>
                                <InsightBars
                                    title="Top 5 reasons for making a move"
                                    subtitle="Why do workers change industry?"
                                    data={movesData.map(({answer, percent}) => ({
                                        label: answer,
                                        value: percent,
                                        percentLabel: percent,
                                    }))}
                                    color="to"
                                />
                            </Box>
                        </div>
                        <div className=" col-12 col-md-6">
                            <p>
                                According to our insights, a quarter (24%) of people who changed
                                industries just had the itch to do something different, 24%
                                wanted a better work-life balance, and 21% simply weren’t
                                enjoying their previous role anymore.
                            </p>
                            <p>
                                In fact, three quarters (76%) of people now say that work-life
                                balance is more important to their career decision-making than
                                ever before.
                            </p>
                            <InsightDots right/>
                        </div>
                    </div>

                    <p className="pt-4">
                        As for salary? It seems money still talks, with over a fifth (19%)
                        of career changers wooed by the{" "}
                        <a
                            href="https://www.totaljobs.com/recruiters/sector-salary-guides-download"
                            target="_blank"
                            rel="noreferrer"
                        >
                            competitive salaries
                        </a>{" "}
                        offered in a different industry.
                    </p>
                    <InsightDots left/>
                    <p>
                        These motivators held fast during the Covid-19 pandemic, seeing 1 in
                        5 people join a new industry.
                    </p>
                    <p>
                        However, necessity also emerged as a primary driver during this period, with a third (32%) of
                        people saying their move was a needs-must decision, versus 18% who said this was their reason
                        for changing careers before Covid-19.
                    </p>
                    <p>
                        The pandemic also saw insecure work become more commonplace, and
                        some industries had to press pause on hiring altogether. In fact,
                        nearly a fifth (19%) of job changers said they were forced to look
                        elsewhere because there weren’t enough roles available in the
                        industry they were part of.
                    </p>
                    <InsightDots right/>
                    <p>
                        Notably, a third (31%) of Covid-era career changers said that the
                        pandemic played no role in their decision to move whatsoever, a
                        reminder that many people will ultimately be driven by their
                        individual, localised circumstances over anything else.
                    </p>
                </InsightContent>

                <InsightHeader
                    id="stick"
                    title="Making it stick"
                    subtitle="What would encourage potential career changers to stay?"
                    onShowSection={onShowSection}
                    onHideSection={onHideSection}
                    lineJoin={["#8C0E51", "#8C0E51"]}
                />

                <InsightContent>
                    <p>
                        Of course, not all job moves are to a whole new industry. Many
                        workers choose to stick with their employer by landing a promotion
                        or sidestepping into a different team, for example.
                    </p>
                    <InsightDots right/>
                    <p>
                        But this employer loyalty varies depending on the industry someone
                        works in:
                    </p>

                    <Box faded>
                        <h4>
                            How long, on average, do people stick with the same employer?
                        </h4>

                        <InsightLoyalty/>
                    </Box>

                    <p className="pt-4">
                        Policing and Public Sector staff are the most likely to stay with their employer for the long
                        haul, working an average of 6.0 and 5.4 years respectively, before moving on. Farming and
                        Agriculture staff follow closely behind, sticking with the same employer for an average of 5.1
                        years.
                    </p>
                    <InsightDots right/>
                    <p>
                        In contrast, Marketing and PR employers see staff move after the shortest length of time, at
                        2.8 and 3.0 years respectively.
                    </p>
                    <InsightDots left/>
                    <p>
                        Not only must employers invest resources to fill vacancies more frequently, but they lose
                        valuable skills when staff leave. This is because when looking across all 40 industries, the
                        average worker resigns with a cumulated 7.4 years of industry experience under their belt.
                    </p>
                    <p>
                        So what would keep workers, and their valuable experience, in the
                        fold?
                    </p>

                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <Box faded>
                                <InsightBars
                                    title="Top 5 ways to retain workers"
                                    subtitle="What would keep workers from moving to a new industry?"
                                    data={retainData.map(({answer, percent}) => ({
                                        label: answer,
                                        value: percent,
                                        percentLabel: percent,
                                    }))}
                                    color="retained"
                                />
                            </Box>
                        </div>
                        <div className="col-12 col-md-6">
                            <p>1 in 3 people say a pay rise would do the trick.</p>
                            <p>
                                However non-monetary incentives – like a better work-life
                                balance or opportunities for learning – would see 30% and 23%
                                stay within their industry, respectively.
                            </p>
                            <p>
                                The emphasis on these lifestyle and development benefits could
                                hint at the high levels of pressure felt by some staff during
                                the pandemic, not to mention the{" "}
                                <a
                                    href="https://www.ons.gov.uk/employmentandlabourmarket/peopleinwork/labourproductivity/articles/homeworkinghoursrewardsandopportunitiesintheuk2011to2020/2021-04-19"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    increase in unpaid overtime
                                </a>{" "}
                                work, and reduction of L&D opportunities due to budgets getting
                                directed elsewhere.
                            </p>
                        </div>
                    </div>

                    <p className="pt-4">
                        For employers, this underscores how crucial it is to invest in
                        lifestyle and wellbeing programmes going forward, while striving to
                        unlock employee potential through{" "}
                        <a
                            href="https://www.totaljobs.com/recruiter-advice/mid-career-workers-are-the-most-willing-to-retrain-according-to-global-talent-survey-of-over-208000-workers/?WT.mc_id=E_A_BA_hub"
                            target="_blank"
                            rel="noreferrer"
                        >
                            upskilling initiatives.
                        </a>
                    </p>
                    <InsightDots right/>
                    <p>
                        We have already seen the impact this can have: 16% of people say
                        they feel more loyal to their employer following the pandemic, which
                        is likely due to how their{" "}
                        <a
                            href="https://www.totaljobs.com/recruiter-advice/supporting-the-health-and-wellbeing-of-employees"
                            target="_blank"
                            rel="noreferrer"
                        >
                            wellbeing was prioritised
                        </a>{" "}
                        as part of their employer’s wider Covid-19 response.
                    </p>
                    <p>
                        Ultimately, employers who can balance ambitious targets with
                        empathetic and transparent leadership will reap the rewards of
                        loyalty and retention in the long run.
                    </p>
                </InsightContent>

                <InsightHeader
                    id="ahead"
                    title="Looking ahead"
                    subtitle="How to attract and keep the people you need"
                    onShowSection={onShowSection}
                    onHideSection={onHideSection}
                    lineJoin={["#8C0E51", "#F06C30"]}
                />

                <InsightContent>
                    <p>
                        A ‘career for life’ is a less trodden path nowadays. Our insights
                        reveal that nearly 4 in 10 (38%) workers believe that switching
                        industries is now more acceptable to employers.
                    </p>
                    <p>
                        No longer a red flag of employee flakiness, career changes represent
                        a tactical way for candidates to round out their experience, better
                        their pay or lifestyle, or land work when the market is tough.
                    </p>
                    <InsightDots left/>
                    <p>
                        In fact, three quarters (75%) of workers say they’re now considering
                        switching industries, with a quarter (26%) ready to do so in the
                        next month*, and 27% in the next two to three months.*
                    </p>
                    <p>
                        This appetite for change presents a ripe opportunity for employers
                        looking to build out recruitment strategies for the future.
                    </p>

                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <Box faded>
                                <InsightBars
                                    title="Top 5 ways to attract workers"
                                    subtitle="What could you offer to win over workers considering switching industries?"
                                    data={attractData.map(({answer, percent}) => ({
                                        label: answer,
                                        value: percent,
                                        percentLabel: percent,
                                    }))}
                                    color="to"
                                />
                            </Box>
                        </div>
                        <div className="col-12 col-md-6">
                            <p>
                                When asked in our survey, the majority of workers said they
                                could be swayed to change industries if offered: a better
                                work-life balance (57%), an increase in salary (52%), or the
                                opportunity to learn new skills (38%).
                            </p>
                            <InsightDots right/>
                            <p>
                                With these incentives, and an understanding of common{" "}
                                <Link to="/">cross-industry movements</Link> in mind, businesses
                                can improve their employee value proposition and tap into new
                                talent pools – all while keeping wellbeing and work-life balance
                                front of mind for the existing workforce too.
                            </p>
                        </div>
                    </div>
                    <InsightDots left/>

                    <p className="pt-4">
                        Not only could this approach help plug skills shortages and
                        introduce{" "}
                        <a
                            href="https://www.totaljobs.com/recruiter-advice/how-in-demand-industries-can-source-candidates-using-transferable-skills"
                            target="_blank"
                            rel="noreferrer"
                        >
                            transferable skills
                        </a>{" "}
                        into your business, it could also build out teams with people who
                        have a crucial mindset for long-term growth: an openness and
                        readiness to change.
                    </p>
                </InsightContent>

                <div className="container">
                    <div className="row pt-4">
                        <div className="col-12 col-lg-9 ms-auto">
                            <h2>Learn more about career changes</h2>

                            <InsightLearnMore
                                title="Career Change Tracker"
                                description="Visit our Career Change Tracker to explore talent movements across industries over time."
                                image={TrackerLearnMoreImage}
                                link={<Link to="/">Visit Tracker</Link>}
                            />

                            <InsightLearnMore
                                title="Industry factsheets"
                                description="Download our industry factsheets to see how talent moves to, from and within your industry, with advice on how to build these insights into your recruitment plans."
                                image={IndustryLearnMoreImage}
                                link={
                                    <a
                                        href="https://www.totaljobs.com/recruiters/career-change-factsheets/?WT.mc_id=E_A_BA_CCT"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Download factsheets
                                    </a>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <InsightsFooter/>
        </Layout>
    );
};

export default InsightsPage;
