import { useState, useEffect } from "react";

export const useClientWidth = () => {
  const [clientWidth, setClientWidth] = useState(0);

  useEffect(() => {
    const listener = () => {
      setClientWidth(document.documentElement.clientWidth);
    };

    listener();

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  return clientWidth;
};
