import orderBy from "lodash/orderBy";
import find from "lodash/find";
import meanBy from "lodash/meanBy";
import maxBy from "lodash/maxBy";

import durationData from "../data/duration.json";

const dataCount = durationData.length;
const sortedData = orderBy(durationData, "avg_years_job", "desc");

export const getTopIndustries = () => sortedData.slice(0, 5);
export const getLowestIndustries = () => sortedData.slice(dataCount - 5);

export const getIndustryDuration = (industry) =>
  find(durationData, { industry });

export const getAverageDuration = (property) => meanBy(durationData, property);
export const getMaxDuration = (property) =>
  maxBy(durationData, (data) => data[property])[property];
