import React from "react";
import cn from "classnames";
import { withSize } from "react-sizeme";

import { useClientWidth } from "../../hooks/useClientWidth";

import styles from "./styles.module.scss";

const Contents = ({ contents = [], current, size }) => {
  const clientWidth = useClientWidth();
  const { width } = size;

  const left = (clientWidth - width) / 2;

  return (
    <div className={cn("container", styles.container)}>
      <div
        className={cn(styles.box)}
        style={{ left: left }}
        aria-label="table of contents"
      >
        <div className={styles.header}>Table of contents</div>

        <ol className={styles.links}>
          {contents.map(({ label, id }) => (
            <li key={id}>
              <a
                className={cn(styles.link, { [styles.active]: id === current })}
                href={`#${id}`}
              >
                {label}
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default withSize()(Contents);
