import React from "react";
import CountUp from "react-countup";
import Box from "../Box";
import Bar from "../Bar";
import { useOnScreen } from "../../hooks/useOnScreen";

const InsightPercentBox = ({ value, text }) => {
  const [ref, isOnScreen] = useOnScreen();

  const displayValue = isOnScreen ? value : 0;

  return (
    <div ref={ref}>
      <Box faded>
        <span className="type-34 fw-bold color-changed">
          <CountUp end={displayValue} suffix="%" duration="0.5" />
        </span>
        <span className="d-block">{text}</span>
        <div className="mt-2">
          <Bar
            value={displayValue}
            bgClassName="bg-changed faded"
            className="bg-changed"
          />
        </div>
      </Box>
    </div>
  );
};

export default InsightPercentBox;
