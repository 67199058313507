import React from "react";
import times from "lodash/times";
import round from "lodash/round";
import cn from "classnames";
import {withSize} from "react-sizeme";
import {useOnScreen} from "../../../hooks/useOnScreen";
import CountUp from "react-countup";

import styles from "./styles.module.scss";

const PillBar = ({value, color, size}) => {
  const [ref, isOnScreen] = useOnScreen();
  const rounded = round(value, 1);

  const {width} = size;

  const pillWidth = width / 10;

  const full = Math.floor(value);
  const remainder = round(rounded % 1, 1);

  const duration = (rounded + 1) * 0.3;

  return (
    <div ref={ref} className={styles.container}>
      <div
        className={styles.pillContainer}
        style={{
          width: isOnScreen ? value * pillWidth : 0,
          transition: `width ${duration}s linear`,
        }}
      >
        {times(rounded).map((index) => (
          <div
            key={index}
            className={styles.pillOuter}
            style={{width: pillWidth, left: index * pillWidth}}
          >
            <span
              className={cn(styles.pill, `bg-${color}`)}
              style={{width: "100%"}}
            />
          </div>
        ))}

        {remainder !== 0 && (
          <div
            className={styles.pillOuter}
            style={{
              width: Math.max(pillWidth * remainder, 10),
              left: full * pillWidth,
            }}
          >
                  <span
                    className={cn(styles.pill, `bg-${color}`)}
                    style={{width: "100%"}}
                  />
          </div>
        )}
      </div>

      <span
        className={styles.label}
        style={{
          left: `${isOnScreen ? value * pillWidth : 0}px`,
          opacity: `${isOnScreen ? 1 : 0}`,
          transition: `left ${duration}s linear, opacity 500ms`,
        }}
      >
        <CountUp
          end={isOnScreen ? value : 0}
          decimals={1}
          duration={duration}
          suffix=" years"
        />
      </span>
    </div>
  );
};

export default withSize()(PillBar);
