import React from "react";

import styles from "./styles.module.scss";

const InsightLearnMore = ({ title, description, image, link }) => (
  <div className={styles.container}>
    <div>
      <div className={styles.image}>
        <img alt={title} src={image} />
      </div>
    </div>

    <div>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.description}>
        {description}
        <br />
        {link}
      </p>
    </div>
  </div>
);

export default InsightLearnMore;
