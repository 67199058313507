import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

const InsightContent = ({ children, flipped = false }) => (
  <div className={cn(styles.container, { [styles.flipped]: flipped })}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-9 ms-auto">{children}</div>
      </div>
    </div>
  </div>
);

export default InsightContent;
