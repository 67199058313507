import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import random from "lodash/random";
import sample from "lodash/sample";
import { useOnScreen } from "../../hooks/useOnScreen";
import styles from "./styles.module.scss";

const xPositions = [10, 100];
const yPositions = [30, 100];

const InsightDots = ({ left = false, right = false }) => {
  const [ref, isOnScreen] = useOnScreen(0.2, false);
  const [config, setConfig] = useState({ x: 0, y: 0, color: "#fff" });

  const startX = right ? 50 : 0;

  useEffect(() => {
    const x = right
      ? random(xPositions[0], xPositions[1])
      : random(-xPositions[0], -xPositions[1]);
    const y =
      random(1) > 0
        ? random(yPositions[0], yPositions[1])
        : random(-yPositions[0], -yPositions[1]);
    const colors = ["#8C0E51", "#0043A6", "#F06C30"];
    const color = sample(colors);

    setConfig({ x, y, color });
  }, [right]);

  const { x, y, color } = config;

  return (
    <div className={styles.container} ref={ref}>
      <svg
        className={cn(styles.dots, {
          [styles.right]: right,
          [styles.left]: left,
        })}
      >
        <motion.path
          stroke={color}
          strokeWidth={16}
          opacity={0.25}
          fill="transparent"
          animate={{
            d: isOnScreen
              ? `M ${startX} 0 L ${startX + x} ${y}`
              : `M ${startX} 0 L ${startX} 0`,
          }}
          transition={{ duration: 1 }}
        />

        <circle cx={startX} cy="0" className={styles.dot} fill="#8C0E51" />
        <circle className={styles.dot} fill={color} cx={startX + x} cy={y} />
      </svg>
    </div>
  );
};

export default InsightDots;
