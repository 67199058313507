import React from "react";
import Bar from "../Bar";

import styles from "./styles.module.scss";

const InsightBars = ({ title, subtitle, data, flip, color }) => (
  <>
    <h4>{title}</h4>
    <p className={styles.subtitle}>{subtitle}</p>

    {data.map(({ label, value, percentLabel, valueLabel }) => (
      <div key={label} className={styles.bar}>
        <strong className={styles.label}>{label}</strong>
        <Bar
          value={value}
          height={20}
          pill={`bg-${color}`}
          className={`bg-${color} faded`}
          bgClassName="bg-light-grey"
          flip={flip}
          percentLabel={percentLabel}
          valueLabel={valueLabel}
          animate
        />
      </div>
    ))}
  </>
);

export default InsightBars;
