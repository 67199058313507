import React from "react";
import cn from "classnames";

import FooterNav from "../FooterNav";

import styles from "./styles.module.scss";

const InsightsFooter = () => (
  <footer className={styles.footer}>
    <div className="container">
      <p className={cn("color-grey type-16", styles.footerText)}>
        *Dates are projected from a Totaljobs survey of 4,491 UK-based workers
        that ran in August 2021. Find more information on our data sources
        below.
        <br />
        <br />
        <strong>About the data: </strong>
        <br />
        The data powering our Career Change Tracker and resulting insights
        listed in this article come from two sources:
      </p>
      <ul className="color-grey type-16">
        <li>
          A StepStone-developed data source derived from proprietary candidate
          information pulled from anonymised online profiles and CVs held across
          Totaljobs Group Limited and StepStone Group, in addition to external
          sources. We use data points such as a candidate’s discipline, job
          title, tenure, years of experience and previous employer to compile
          our data source ("Data Points"). This structured data is company
          centric (i.e. we use all the data about a company’s employees, rather
          than obtaining specific Data Points) and has been aggregated for this
          report by company industry and job discipline.
        </li>
        <li>
          UK survey: A survey of 4,491 UK-based workers from the Totaljobs
          database in August 2021.
        </li>
      </ul>

      <FooterNav />
    </div>
  </footer>
);

export default InsightsFooter;
